import '/assets/styles/pages/checkout.scss';
import { useEffect, useMemo, useState, useRef, Fragment } from 'react';
import loadable from '@loadable/component'

// Partials
import { Input, Form } from '/views/partials/forms';
import Loader from '/views/partials/loader';
import Btn from '/views/partials/btn';
import Pricebox from '/views/partials/pricebox';
import Productbox from '/views/partials/productbox';
import EmptyMessage from '/views/partials/empty-message';
import Slider from '/views/partials/slider';
import Placeholder from '/views/partials/placeholder';
import Icon from '@wearetla/tla-essentials-tools/partials/icon';
import Img from '@wearetla/tla-essentials-tools/partials/img';
import StickyCheckoutRightSection from '/views/partials/sticky-checkout-right-section';

// Context
import { useNavigator } from '@wearetla/tla-essentials-tools/utilities/navigator'
import { useBasket } from '/controllers/basket';
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals';
import { useAuth } from '@wearetla/tla-essentials-tools/utilities/auth';
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';
import { useAnalytics, parseAnalyticsProduct } from '/utilities/analytics';

// Functions
import formatDate from '@wearetla/tla-essentials-tools/functions/format-date';

// Services
import saleServices from '/services/sale'
import basketServices from '/services/basket'
import siteServices from '/services/site'
import userServices from '/services/user'

// Lazy Sections
const ProductCarousel = loadable(() => import('/views/sections/product-carousel'));

const Basket = () => {
	const _mounted = useRef(false);

	const { sendAnalyticsEvent } = useAnalytics();
	const { openModal } = useModals();
	const { userData } = useAuth();
	const { mobile } = useBreakpoints();
	const { sysParameters, setNotFound } = useGlobalState();
	const { initialized: basketInitialized, busy: productUpdating, summary, products, clearBasket: clearBasketOnContext, getBasketData, prepare: prepareBasket } = useBasket();
	const { redirect } = useNavigator();

	const favoriteProducts = []

	const [loading, setLoading] = useState(true);
	const [basketFetched, setBasketFetched] = useState(false);
	const [promoCodeLoading, setPromoCodeLoading] = useState(false);
	const [giftCodes, setGiftCodes] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [addExchangeCard/*, setAddExchangeCard*/] = useState(false);

	const { services, campaign } = useMemo(() => {
		if(basketFetched && summary) {
			let newCampaign = false;
			const newServices = summary.services ? summary.services.map(service => {
				let promotionMatch = summary.promotion_code && summary.promotion_code.length > 0 && (service.relation_name === 'campaign' || service.relation_name === 'free_cargo_promotion');
				if(promotionMatch) {
					newCampaign = {
						...service,
						promotion_code: summary.promotion_code,
					}

					return {
						...service,
						name: service.service_code ? service.service_code : service.name,
					}
				}
				else if(service.relation_name === 'gift_pack') {
					return {
						...service,
						name: service.service_code,
					}
				}
				else {
					return service;
				}
			}) : false;

			return {
				services: newServices,
				campaign: newCampaign,
			}
		}
		else {
			return {
				services: false,
				campaign: false,
			}
		}
	}, [summary, basketFetched])

	// const sendAnalytics = (/*basketData*/) => {
	// 	// sendGtagEvent({
	// 	// 	event: 'conversion',
	// 	// includeBasketContent: false,
	// 	// 	sendTo: 'AW-381727730/HgHVCKadv7UCEPLngrYB',
	// 	// 	value: basketData.grand_total.original,
	// 	// });
	// }

	useEffect(() => {
		if(basketInitialized && products) {
			setTimeout(() => {
				sendAnalyticsEvent({
					event: 'view_basket',
				})
			}, 100)
		}
	}, [basketInitialized, products, summary, sendAnalyticsEvent])

	useEffect(() => {
		_mounted.current = true;

		basketServices.get().then((payload) => {
			if(_mounted.current) {
				// sendAnalytics(payload.summary);
				setBasketFetched(true);
			}
		});

		// if(window.localStorage?.getItem(config.storageKeys.exchangeCard) !== null) {
		// 	setAddExchangeCard(true);
		// }

		return () => {
			_mounted.current = false;
		}
	}, [])

	useEffect(() => {
		if(userData) {
			userServices.getGiftCodes().then((payload) => {
				setGiftCodes(payload.active.map((code) => ({
					...code,
					value: code.promotion_code,
					label: <>
						<p>{code.name}</p>
						<span>{code.description}</span>
					</>
				})));
			}).catch(() => {
			})
		}
	}, [userData])

	const freeCargoDiff = useMemo(() => {
		if(summary && products && basketFetched) {
			setLoading(false);

			const hasFreeCargoProducts = products.reduce((free, product) => {
				return free || product.cargo_free;
			}, false)

			// Ücretsiz kargolu ürün varsa ücretsiz kargo akışını yarıda kes ücretsiz olarak yansıt.
			if(hasFreeCargoProducts) { return 0 }

			const diff = (sysParameters?.['free_cargo_price_limit']) ? (parseInt(sysParameters['free_cargo_price_limit'].description) - summary.grand_total.original) : false;

			return((campaign?.relation_name !== 'free_cargo_promotion' && diff > 0) ? diff.toFixed(2).replace('.', ',') : false);
		}

		return false;
	}, [summary, products, sysParameters, basketFetched, campaign])

	const [multipleCargoDates, latestCargoDate] = useMemo(() => {
		if(products?.length) {
			const dateOptions = products.reduce((dates, product) => {
				if(product.given_cargo_day && !dates.includes(product.given_cargo_day)) {
					return [
						...dates,
						product.given_cargo_day,
					]
				}

				return dates;
			}, []).sort();

			return [
				// Birden fazla tarih verilen ürün varsa.
				dateOptions.length > 1,
				formatDate(dateOptions[dateOptions.length -1], 'd MMMM yyyy EEEE')
			];
		}

		return [false, null];
	}, [products]);

	const refetch = () => {
		setLoading(true);

		getBasketData().then(() => {
		}).catch(() => {
			setNotFound(true);
		}).finally(() => {
			setLoading(false);
		})
	}

	const removePromotion = () => {
		openModal('confirm', {
			message: `Promosyon kodunu kaldırmak istediğinizden emin misiniz?`,
			onConfirm: () => {
				setPromoCodeLoading(false);

				saleServices.removePromotionCode().then((payload) => {
					refetch();
				}).catch((error) => {
					openModal('alert', { message: error })
				}).finally(() => {
					setPromoCodeLoading(false);
				})
			},
		})
	}

	const addPromotion = (code) => {
		if(code) {
			setLoading(true);
			saleServices.addPromotionCode(code.promotion_code).then(() => {
				refetch();
			}).catch(() => {
				setLoading(false);
			});
		}
	}

	const proceed = () => {
		const handleChangeError = (basketConfirmationErrors) => {
			if(_mounted.current) {
				const basketSnapshot = [
					...products
				]
				basketServices.get().then(() => {
					if(_mounted.current) {
						setSubmitting(false);
						openModal('basket-stock-warnings', {
							basketSnapshot: basketSnapshot,
							products: basketConfirmationErrors,
							onContinue: () => {
								prepare();
							}
						})
					}
				});
			}
		}
		const prepare = () => {
			setSubmitting(true);

			prepareBasket({ addExchangeCard }).then((payload) => {
				sendAnalyticsEvent({
					event: 'begin_checkout',
					includeBasketContent: false,
					value: payload.summary.grand_total.original,
					products: payload.products.map(product => (
						parseAnalyticsProduct(product)
					)),
				});
				
				redirect('checkout');
			}).catch((e) => {
				if(_mounted.current) {
					if(e.messages) {
						handleChangeError(e.messages);
					}
					else {
						console.warn('Prepare hatası: ', e);
						setSubmitting(false);
					}
				}
			})
			saleServices.prepare({
				exchange_card: addExchangeCard,
			}).then((payload) => {
				if(_mounted.current) {
					window.localStorage?.setItem('checkout-sale-id', payload.id);

					if(payload.has_support_products) {
						window.localStorage?.setItem('checkout-sale-has-support-products', 1);
					}
					else {
						window.localStorage?.removeItem('checkout-sale-has-support-products', 1);
					}

					sendAnalyticsEvent({
						event: 'begin_checkout',
						includeBasketContent: false,
						value: payload.summary.grand_total.original,
						products: payload.products.map(product => (
							parseAnalyticsProduct(product)
						)),
					});

					redirect('checkout');
				}
			}).catch((e) => {
				
			})
		}

		if(!userData) {
			openModal('login-message', { message: 'Satın alma işlemine devam etmek için üye girişi yapmalısınız.', redirect: 'basket' })
		}
		else {
			setSubmitting(true);

			basketServices.confirmBasket().then(() => {
				prepare();
			}).catch(handleChangeError)
		}
	}

	const clearBasket = () => {
		openModal('confirm', {
			message: 'Sepetinizi boşaltmak istediğinizden emin misiniz?',
			onConfirm: () => {
				clearBasketOnContext();
			}
		})
	}

	// const exchangeCardInputChange = (val) => {
	// 	if(val) {
	// 		window.localStorage?.setItem(config.storageKeys.exchangeCard, '1')
	// 	}
	// 	else {
	// 		window.localStorage?.removeItem(config.storageKeys.exchangeCard);
	// 	}
	// }

	return (
		<main className="page checkout loader-container">
			<Loader loading={loading} message="Sepetiniz getiriliyor..." />
			{(products && summary) &&
				<div className="checkout-wrap wrapper">
					<section className="checkout-left">
						<div className="checkout-section checkout-basket">
							<div className="section-header">
								<h1 className="section-title big">Sepetim</h1>

								{products.length !== 0 &&
									<button
										data-gtm-id="clearBasket"
										disabled={submitting}
										type="button"
										onClick={clearBasket}
										className="basket-clearbtn">
										sepeti temizle
									</button>
								}
							</div>

							{!!latestCargoDate &&
								<div className="basket-deliveryinfo">
									<Icon name="cargo" /> <p className="cargoinfo-text">{multipleCargoDates ? <>Siparişinizde farklı sevkiyat gününe sahip ürünler var bu nedenle ürünleriniz</> : <>Ürünleriniz</>} en geç <span>{latestCargoDate}</span> tarihinde kargoya verilecektir.</p>
								</div>
							}

							{!!(products.length !== 0 && freeCargoDiff) &&
								<div className="basket-cargoinfo">
									<Icon name="feature-cargo" /> <p className="cargoinfo-text">Ücretsiz Kargo için <span>{freeCargoDiff}</span> TL'lik daha ürün eklemelisiniz</p>
								</div>
							}

							{/* <div className="basket-message">
								<strong>20-25 Temmuz</strong> tarihleri arasında verilen siparişler bayram dolayısıyla <strong>26.07.2021 Pazartesi</strong> günü itibari ile kargoya verilecektir. <br />Anlayışınız için teşekkür ederiz.
							</div> */}

							{products.length !== 0 ?
								<ul className="basket-list">
									{products.map((item) => (
										<li className="list-product" key={item.serial_id}>
											<Productbox
												disabled={submitting}
												type="basket"
												product={item} />
										</li>
									))}
								</ul>
								:
								<EmptyMessage className="basket-empty" icon="cart">
									Sepetinizde ürün bulunmuyor.
								</EmptyMessage>
							}
						</div>

						{!mobile &&
							<BasketWidgets favoriteProducts={favoriteProducts} />
						}
					</section>

					<StickyCheckoutRightSection>
						<Form className="checkout-sum" onSubmit={proceed}>
							<header className="sum-header">
								<h2 className="header-title">
									<strong>Toplam Tutar</strong> (KDV Dahil)
								</h2>
								<Pricebox
									big
									showCurrency
									className="header-total"
									price={summary.grand_total.original} />
								<Btn
									data-gtm-id="goToCheckout"
									type="submit"
									disabled={submitting || loading || productUpdating || (products && products.length === 0)}
									loading={submitting}
									className="header-cta primary block">
									Devam Et
								</Btn>
							</header>

							<ul className="sum-services">
								<li className="services-service">
									<strong>Ürün Toplamı</strong>
									<Pricebox
										price={summary.price.original}
										showCurrency />
								</li>
								{services &&
									<>
										{services.map((service, nth) => (
											<Fragment key={nth}>
												{(service.relation_name !== 'free_cargo_promotion') &&
													<li className="services-service">
														<strong>{service.name}</strong>
														<Pricebox
															strike={service.price.original < 0}
															price={service.price.original}
															showCurrency />
													</li>
												}
											</Fragment>
										))}
									</>
								}
								{!freeCargoDiff &&
									<li className="services-service">
										<strong>Kargo</strong>
										<span className="label uppercase small orange-bg">Ücretsiz</span>
									</li>
								}
								{campaign &&
									<li className="services-promocode">
										<strong className="promocode-title">
											{campaign.promotion_code}
										</strong>
										<p className="promocode-description">{campaign.name}</p>
										<Btn
											disabled={submitting || promoCodeLoading}
											onClick={removePromotion}
											className="smallest"
											icon="close">
											iptal et
										</Btn>
									</li>
								}
							</ul>

							{!campaign &&
								<div className="sum-btnwrap">
									{(giftCodes && giftCodes.length > 0) &&
										<Input
											className="sum-promocodes small"
											type="select"
											disabled={loading}
											options={giftCodes}
											onChange={addPromotion}
											label="Hediye Çeklerim" />
									}
									<Btn
										data-gtm-id="addPromoCode"
										className={`pink-bg narrow small${!mobile ? ' block' : ''}`}
										disabled={loading || submitting}
										onClick={() => {
											openModal('basket-promo', { onSuccess: refetch });
										}}
										icon="discount">
										{(giftCodes && giftCodes.length > 0) ? 'Promosyon Kodu Gir' : 'Promosyon Kodum Var'}
									</Btn>
								</div>
							}

							{/* <div className="sum-bottomcontrols">
								<Input
									type="checkbox"
									checked={addExchangeCard}
									onChange={(val) => { setAddExchangeCard(val ? true : false) }}
									label="Siparişe değişim kartı ekle"
									id="input-exchange-card"
									name="exchange-card"
									value={1} />
							</div> */}
						</Form>
						{/* <div className="checkout-storebanner">
							<p className="storebanner-text">
								Siparişinizi <strong>Mağazadan Teslim Alabilirsiniz</strong>
							</p>
							<Icon className="storebanner-icon" name="feature-change" />
						</div> */}
					</StickyCheckoutRightSection>

					{mobile &&
						<BasketWidgets favoriteProducts={favoriteProducts} />
					}
				</div>
			}
		</main>
	)
}

const BasketWidgets = () => {
	const _mounted = useRef(false);

	const favoriteProducts = [];

	const { mobile } = useBreakpoints();
	const { openModal } = useModals();

	const [campaigns, setCampaigns] = useState([false, false]);
	const [interestingProducts, setInterestingProducts] = useState(false);
	const [archiveProducts, setArchiveProducts] = useState(false);

	useEffect(() => {
		_mounted.current = true;
		siteServices.getCampaigns().then((payload) => {
			if(_mounted.current) {
				setCampaigns(payload);
			}
		}).catch(() => {
			if(_mounted.current) {
				setCampaigns(null);
			}
		})

		basketServices.getArchive().then((payload) => {
			if(_mounted.current) {
				setArchiveProducts(payload);
			}
		}).catch(() => {
			if(_mounted.current) {
				setArchiveProducts(null);
			}
		})

		siteServices.getShowcase(3).then((payload) => {
			if(_mounted.current) {
				setInterestingProducts(payload);
			}
		}).catch(() => {
			if(_mounted.current) {
				setInterestingProducts(null);
			}
		})

		return () => {
			_mounted.current = false;
		}
	}, [])

	return (
		<>
			{(campaigns && campaigns?.length !== 0) &&
				<div className="checkout-section checkout-campaigns">
					<strong className="section-title small">Sepet Kampanyaları</strong>
					<Slider
						className="campaigns-slider"
						params={{
							loopAdditionalSlides: mobile ? 1 : 0,
							slidesPerView: mobile ? 1 : 3,
							loop: false,
							navigation: !mobile,
						}}>
						{campaigns.map((campaign, nth) => (
							<Fragment key={campaign ? campaign.id : `c-${nth}`}>
								{campaign === false ?
									<div className="campaign-button">
										<Placeholder className="campaign-imagewrap" />
										<Placeholder className="campaign-title" />
										<Placeholder className="campaign-description" />
										<div className="campaign-ctawrap">
											<Placeholder />
										</div>
									</div>
								:
									<button className="campaign-button" onClick={() => { openModal('campaign', { campaign: campaign }) }}>
										<div className="campaign-imagewrap">
											<Img
												src={campaign.image}
												className="campaign-image" />
										</div>
										<strong className="campaign-title">{campaign.title}</strong>
										<p className="campaign-description">{campaign.sort_description}</p>

										<p className="campaign-ctawrap">Kampanya detayı <Icon name="arrow-right" /></p>
									</button>
								}
							</Fragment>
						))}
					</Slider>
				</div>
			}

			{(
				(archiveProducts && archiveProducts.length > 0) || 
				(favoriteProducts && favoriteProducts.length > 0) || 
				(interestingProducts && interestingProducts.products.length > 0)
			) &&
				<ProductCarousel
					noWrap tabs
					className="checkout-section checkout-lists"
					data={[
						(archiveProducts && archiveProducts.length > 0 ?
						{
							title: 'Daha Önce Sepete Eklediğiniz Ürünler',
							icon: 'menu-cart',
							key: 'archive',
							products: archiveProducts,
						} : false), 
						(favoriteProducts && favoriteProducts.length > 0 ?
						{
							title: 'Listenize Eklediğiniz Ürünler',
							icon: 'heart-empty',
							key: 'previous',
							products: favoriteProducts,
						} : false), 
						(interestingProducts && interestingProducts.products.length > 0 ?
						{
							title: interestingProducts.title,
							icon: 'surprise',
							key: 'interesting',
							products: interestingProducts.products,
						} : false),
					]} />
			}
		</>
	)
}

export default Basket